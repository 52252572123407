export default {

    saveNewSubscription(state, subscriptionPayload) {
        state.subscriptions.push(subscriptionPayload);
    },
    setSubscriptions(state, subscriptionsPayload) {
        state.subscriptions = subscriptionsPayload
    },
    setFetchTimestamp(state) {
        state.lastFetch = new Date().getTime();
    }
}