export default {
    subscriptions(state) {
        return state.subscriptions
    },
    hasSubscriptions(state) {
        return state.subscriptions && state.subscriptions.length > 0
    },
    shouldUpdate(state) {
        const lastFetch = state.lastFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        return (currentTimeStamp - lastFetch) / 1000 > 120;
    }
}