import {
    getInfoSchool,
    addTeacher,
    removeTeacher,
    addAssistant,
    removeAssistant,
    getAttendanceTeachers
} from '../../../services/SchoolService.js';

export default {
    async getInfoSchool(context, payloadData) {
        const schoolData = await getInfoSchool(payloadData.token, payloadData.forceLoad);
        context.commit('setInfoSchool', {infoSchool: schoolData.data.school});
        localStorage.setItem('school', JSON.stringify(schoolData.data.school));
    },
    async addTeacher(context, payloadTeacher) {
        await addTeacher(context.rootGetters['school/getSchoolId'], payloadTeacher, context.rootGetters.getToken);
        await context.dispatch('getInfoSchool', {token: context.rootGetters.getToken, forceLoad: true});
    },
    async removeTeacher(context, payloadTeacher) {
        await removeTeacher(context.rootGetters['school/getSchoolId'], payloadTeacher, context.rootGetters.getToken);
        await context.dispatch('getInfoSchool', {token: context.rootGetters.getToken, forceLoad: true});
    },
    async addAssistant(context, payloadAssistant) {
        await addAssistant(context.rootGetters['school/getSchoolId'], payloadAssistant, context.rootGetters.getToken);
        await context.dispatch('getInfoSchool', {token: context.rootGetters.getToken, forceLoad: true});
    },
    async removeAssistant(context, payloadAssistant) {
        await removeAssistant(context.rootGetters['school/getSchoolId'], payloadAssistant, context.rootGetters.getToken);
        await context.dispatch('getInfoSchool', {token: context.rootGetters.getToken, forceLoad: true});
    },
    async getAttendanceTeachers(context) {
        const attendanceTeachers = await getAttendanceTeachers(context.rootGetters['school/getSchoolId'], context.rootGetters.getToken);
        context.commit('setAttendanceTeachers', attendanceTeachers.data.attendaceStats);
    },
    removeInfoSchool(context) {
        localStorage.removeItem('school');
        context.commit('setInfoSchool', {infoSchool: null});
    }
}