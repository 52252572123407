import axios from "axios";
const URL = process.env.VUE_APP_API_URL; 

export async function saveSubscription(data, payloadToken, schoolId) {
    const response = await axios.post(`${URL}/school/${schoolId}/subscription`, data, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return response.data;
}

export async function loadSubscriptions(payloadToken, schoolId) {
    const response = await axios.get(`${URL}/school/${schoolId}/subscription`, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        },
        params: {
            sort: '-createAt',
        }
    });
    return response.data;
}