import { createUserBySchoo, getAllStudentsBySchoolId } from '../../../services/SchoolService.js';
export default {
    async saveNewStudent(context, studentPayload) {
        const newStudent = {
            name: studentPayload.name,
            email: studentPayload.email,
            phone: studentPayload.phone,
            dateOfBirth: studentPayload.dateOfBirth,
        }
        const response = await createUserBySchoo(newStudent, context.rootGetters['school/getSchoolId'], context.rootGetters.getToken);
        const newUser = {...newStudent, _id: response.data.user._id};
        context.commit('saveNewUser', newUser);
    },

    removeCurrentStudentCreated(context) {
        context.commit('saveNewUser', null);
    },

    async loadStudents(context, payload = false) {
        if (!payload.forceRefresh && !context.getters.shouldUpdate) return;
        const students = await getAllStudentsBySchoolId(context.rootGetters['school/getSchoolId'], context.rootGetters.getToken);
        context.commit('setStudents', students.data.students);
        context.commit('setFetchTimestamp');
    }
}