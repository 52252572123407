import { getUsers, teacherGetMyAttendanceByMonth } from '../../../services/UserService.js';

export default {
    async loadUsers(context) {
        const allUsers = await getUsers(context.rootGetters.getToken);
        context.commit('setAllUsers', allUsers.data.users);
    },
    async teacherGetMyAttendancebyMonth(context) {
        const schedule = await teacherGetMyAttendanceByMonth(context.rootGetters.getToken);
        context.commit('teacherSetMyAttendanceByMonth', schedule.data);
    }
}