import { saveDanceStyle, getAllDanceStyle } from '../../../services/StyleDanceService';
export default {
    async addNewTypeDance(context, typeDancePayload) {
        const newTypeDance = { 
            name: typeDancePayload.name,
            description: typeDancePayload.description,
            when: typeDancePayload.when,
            hourStart: typeDancePayload.hourStart,
            hourEnd: typeDancePayload.hourEnd,
            teachers: typeDancePayload.teachers,
            category: typeDancePayload.category,
            days: typeDancePayload.days,
            school: context.rootGetters['school/getSchoolId']
        }
        const response = await saveDanceStyle(newTypeDance, context.rootGetters.getToken);
        if (!response.success) {
            this.showTheError(response.message);
        }
        context.commit('saveNewTypeDance', {...newTypeDance, id: response.data.danceStyle._id});
        context.dispatch('loadTypesDance', {forceRefresh: true});
    },
    async loadTypesDance(context, payload = false) {

        if (!payload.forceRefresh && !context.getters.shouldUpdate) return;

        const response = await getAllDanceStyle(context.rootGetters.getToken, context.rootGetters['school/getSchoolId']);
        if (!response.success) {
            this.showTheError(response.error);        
        }
        context.commit('setTypesDance', response.data.danceStyles);
        context.commit('setFetchTimestamp');
    },

    showTheError(errorMessage) {
        const error = new Error(errorMessage);
        throw error;
    }
}