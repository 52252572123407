import axios from "axios";
const URL = process.env.VUE_APP_API_URL; 

export async function saveDanceStyle(data, payloadToken) {
    const response = await axios.post(`${URL}/dance-style`, data, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return response.data;
}

export async function getAllDanceStyle(payloadToken, schoolId) {
    const response = await axios.get(`${URL}/school/${schoolId}/dance-style`, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return response.data;
}