import { createRouter, createWebHistory } from "vue-router";
import store from './store/index.js';

//Not found
const TheNotFound = () => import('./pages/TheNotFound.vue');

//Auth
const Login = () => import("./pages/auth/LoginUser.vue");
const SignUp = () => import("./pages/auth/SignupUser.vue");

//dashboards
const TheDashboard = () => import('./components/dashboard/TheDashboard.vue');
const TheSchool = () => import('./components/school/TheSchool.vue');

//Student dashboard
const TheDashboardStudents = () => import('./components/dashboard/TheDashboardStudents.vue');


//pages
//----School
//import ListSchool from "./pages/school/ListSchools.vue";
const NewSchool = () => import('./pages/school/NewSchool.vue');
const DetailSchool = () => import('./pages/school/DetailSchool.vue');


//----Students
const ListStudent = () => import('./pages/school/students/ListStudents.vue');
const NewStudent = () => import('./pages/school/students/NewStudent.vue');
const DetailStudent = () => import('./pages/school/students/DetailStudent.vue');


//----Subscriptions
const ListSubscription = () => import('./pages/school/subscriptions/ListSubscriptions.vue');
const NewSubscription = () => import('./pages/school/subscriptions/NewSubscription.vue');
const DetailSubscription = () => import('./pages/school/subscriptions/DetailSubscription.vue');


//----Types of dance
const ListTypeDance = () => import('./pages/school/types-dance/ListTypeDance.vue');
const NewTypeDance = () => import('./pages/school/types-dance/NewTypeDance.vue');
const DetailTypeDance = () => import('./pages/school/types-dance/DetailTypeDance.vue');


//---- Package
const ListPackages = () => import('./pages/school/packages/ListPackages.vue');
const NewPackage = () => import('./pages/school/packages/NewPackage.vue');

//---- Package
const ScheduleList = () => import('./pages/school/schedule/ListSchedule.vue');
const ScheduleDetail = () => import('./pages/school/schedule/DetailSchedule.vue');

//pages
//----Students
const homePage = () => import('./pages/dashboard-user/home/HomePage.vue');
const accountPage = () => import('./pages/dashboard-user/account/AccountPage.vue');


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: 'login',
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                requiresUnauthenticated: true  
            },
            component: Login
        },
        {
            path: '/signup',
            name: 'signup',
            meta: {
                requiresUnauthenticated: true
            },
            component: SignUp
        },
        {
            path: '/home',
            name: 'dashboard-user',
            meta: {
                requiresAuth: true
            },
            component: TheDashboardStudents,
            children: [
                {
                    path: '',
                    name: 'home-user',
                    component: homePage
                },
                {
                    path: 'account',
                    name: 'account-user',
                    component: accountPage
                },
            ]
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: {
                requiresAuth: true
            },
            component: TheDashboard,
            children: [
                {
                    path: '',
                    name: 'home',
                    redirect: '/dashboard/school',
                },
                {
                    path: '/dashboard/school',
                    name: 'school',
                    component: TheSchool,
                    children: [
                        {
                            path: '', 
                            name: 'school-dashboard',
                            redirect: '/dashboard/school/detail',
                        },
                        {
                            path: '/new',
                            name: 'new-school',
                            component: NewSchool,
                        },
                        {
                            path: 'detail',
                            name: 'detail-school',
                            component: DetailSchool
                        },
                        {
                            path: '/dashboard/school/students',
                            name: 'list-students',
                            component: ListStudent,
                        },
                        {
                            path: 'new-student',
                            name: 'new-student',
                            component: NewStudent,
                        },
                        {
                            path: '/detail-student/:id',
                            name: 'detail-student',
                            component: DetailStudent,
                        },
                        {
                            path: 'subscriptions',
                            name: 'list-subscriptions',
                            component: ListSubscription,
                        },
                        {
                            path: 'new-subscription',
                            name: 'new-subscription',
                            component: NewSubscription,
                        },
                        {
                            path: '/detail-subscription/:id',
                            name: 'detail-subscription',
                            component: DetailSubscription,
                        },
                        {
                            path: 'type-dance',
                            name: 'type-dance',
                            component: ListTypeDance
                        },
                        {
                            path: 'new-type-dance',
                            name: 'new-type-dance',
                            component: NewTypeDance
                        },
                        {
                            path: '/detail-type-dance/:id',
                            name: 'detail-type-dance',
                            component: DetailTypeDance
                        },
                        {
                            path: 'packages',
                            name: 'packages',
                            component: ListPackages
                        },
                        {
                            path: 'new-package',
                            name: 'new-package',
                            component: NewPackage
                        },
                        {
                            path: 'schedule',
                            name: 'schedule',
                            component: ScheduleList
                        },
                        {
                            path: 'schedule-detail/:id',
                            name: 'schedule-detail',
                            component: ScheduleDetail,
                            props: true
                        }
                        ,
                        {
                            path: '/:notFound(.*)*',
                            component: TheNotFound
                        }
                    ]
                },
            ]
        },
        {
            path: '/:notFound(.*)',
            component: TheNotFound
        }
    ]
});

router.beforeEach((to, from, next) => {

    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresUnauthenticated && store.getters.isAuthenticated) {
        next({name: 'home-user'});
    } else {
        next();
    }
})

export default router;