import { savePackage, getAllPackageBySchool } from '../../../services/PackageService';
export default {
    async addNewPackage(context, packagePayload) {
        const newPackage = {
            name: packagePayload.name,
            classes: packagePayload.classes,
            price: packagePayload.price,
            totalClasses: packagePayload.totalClasses
        }
        const responseBack = await savePackage(newPackage, context.rootGetters.getToken, context.rootGetters['school/getSchoolId']);
        context.commit('saveNewPackage', {
            ...newPackage,
            id: responseBack.data.package._id
        });
        context.dispatch('loadPackages', {forceRefresh: true});
    },
    async loadPackages(context, payload = false) {
        
        if (!payload.forceRefresh && !context.getters.shouldUpdate) return;
        
        const response = await getAllPackageBySchool(context.rootGetters.getToken, context.rootGetters['school/getSchoolId']);
        context.commit('setListPackages', response.data.packages);
        context.commit('setFetchTimestamp');
    }
}