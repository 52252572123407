import axios from "axios";
const URL = process.env.VUE_APP_API_URL; 

export async function savePackage(data, payloadToken, schoolId) {
    const response = await axios.post(`${URL}/school/${schoolId}/package`, data, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return response.data;
}

export async function getAllPackageBySchool(payloadToken, schoolId) {
    const response = await axios.get(`${URL}/school/${schoolId}/package`, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return response.data;
}