import {
    showTheError,
    removeLocalStorage,
    saveLocalStorage,
    signup,
    login,
    loginWithSocialMedia
} from '../../../services/AuthService.js';

export default {
    async signup(context, payload) {
        const response = await signup(payload);
        context.commit('setUser', {token: response.token, user: response.data.user});
        saveLocalStorage({toekn: response.token, user: response.data.user});
        context.getters.isOwner ? await context.dispatch('school/getInfoSchool', {token: response.token}, {root: true}) : null;
    },
    async login(context, payload) {
        const response = await login(payload);
        context.commit('setUser', response);
        saveLocalStorage(response);
        (context.getters.isOwner || context.getters.isAssistant) ? await context.dispatch('school/getInfoSchool', {token: response.token}, {root: true}) : null;
    },
    async loginWithGoogle(context, payload) {
        try {
            const responseBack = await loginWithSocialMedia(payload.tokenGoogle);
            if (!responseBack.success) {
                showTheError(responseBack.message);
            }
            saveLocalStorage(responseBack.data);
            context.commit('setUser', responseBack.data);
            (context.getters.isOwner || context.getters.isAssistant) ? await context.dispatch('school/getInfoSchool', {token: responseBack.data.token}, {root: true}) : null;
        } catch (err) {
            showTheError(err);
        }
    },
    loginWithApple(context, payload) {
        console.log(context, payload);
    },
    logOut(context) {
        removeLocalStorage();
        context.commit('setUser', {
            token: null,
            currentUser: null
        });
        context.dispatch('school/removeInfoSchool', null, {root: true});
    },
    async checkAuthLocalStorage(context) {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');

        if ( token && user ) {
            context.commit('setUser', {
                token,
                user: JSON.parse(user)
            });
            (context.getters.isOwner || context.getters.isAssistant) ? await context.dispatch('school/getInfoSchool', {token}, {root: true}) : null;
        }
    }
};