import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    state() {
        return {
            userId: '78',//TODO: change this source to get user id
            role: 'owner',
            token: null,
            currentUser: null,
        };
    },
    mutations,
    actions,
    getters,
}