import mutations from "./mutations.js"
import actions from './actions.js';
import getters from './getters.js';
export default {
    namespaced: true,
    state() {
        return {
            listSchedule: [],
            scheduleDetail: null,
            lastFetch: null,
            schedulesMainView: null,
            mySchedulesBySubscription: null
        }
    },
    mutations,
    actions,
    getters
}