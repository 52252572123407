import axios from "axios";
const URL = process.env.VUE_APP_API_URL; 

export async function getInfoSchool(token, forceLoad) {
    const schoolDataInLS = localStorage.getItem('school');
    if (schoolDataInLS && !forceLoad) {
        return {
            data: {
                school: {
                    ...JSON.parse(schoolDataInLS),
                }
            }
        };
    }
    const response = await axios.get(`${URL}/school`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function createUserBySchoo(dataUser, schoolId, token) {
    const response = await axios.post(`${URL}/school/${schoolId}/user`, dataUser, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function getAllStudentsBySchoolId(schoolId, token) {
    const response = await axios.get(`${URL}/school/${schoolId}/students`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function addTeacher(schoolId, dataTeacher, token) {
    const response = await axios.post(`${URL}/school/${schoolId}/teachers`, {
        teacher: dataTeacher
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function removeTeacher(schoolId, dataTeacher, token) {
    const response = await axios.delete(`${URL}/school/${schoolId}/teachers/${dataTeacher}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function addAssistant(schoolId, dataAssistant, token) {
    const response = await axios.post(`${URL}/school/${schoolId}/assistants`, {
        assistant: dataAssistant
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function removeAssistant(schoolId, dataAssistant, token) {
    const response = await axios.delete(`${URL}/school/${schoolId}/assistants/${dataAssistant}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function getAttendanceTeachers(schoolId, token) {
    const response = await axios.get(`${URL}/school/${schoolId}/attendance-teachers`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;

}