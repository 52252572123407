//import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";

const URL = process.env.VUE_APP_API_URL; 

export async function signup(data) {
    const response = await axios.post(`${URL}/users/signup`, data);
    return response.data;
}

export async function login(data) {
    const response = await axios.post(`${URL}/users/login`, data);
    return response.data;
}

export async function loginWithSocialMedia(tokenGoogle) {
    //TODO: need to add token through interceptors 
    const response = await axios.post(`${URL}/users/login-social-media`, {}, {
        headers: {
            Authorization: `Bearer ${tokenGoogle}`
        }
    });
    return response.data;
}

export function saveLocalStorage(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
}
export function removeLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('school');
}

export function showTheError(errorMessage) {
    const error = new Error(errorMessage);
    throw error;
}