export default {
    getCurrentUserId(state) {
        return state.currentUser._id;
    },
    getCurrentUserImg(state) {
        return state.currentUser?.photo;
    },
    getToken(state) {
        return state.token;
    },
    isAuthenticated(state) {
        return !!state.token;
    },
    isOwner(state) {
        return !!state.currentUser.role.find(role => role === 'owner');
    },
    isAssistant(state) {
        return !!state.currentUser.role.find(role => role === 'assistant');
    },
    isTeacher(state) {
        return !!state.currentUser?.role.find(role => role === 'teacher');
    },
    isMySchool(state, _, rootState) {
        return rootState['school'].school?.owner ? rootState['school'].school?.owner._id : false == state.currentUser?._id;
    },
    imTheAssistant(state, _, rootState) {
        return rootState['school'].school?.assistants ? rootState['school'].school?.assistants.find(assistant => assistant._id == state.currentUser?._id): false;
    }
}