export default {
    school(state) {
        return state.school;
    },
    getTeachers(state) {
        return state.school.teachers;
    },
    getSchoolId(state) {
        return state.school?._id;
    },
    getAttendanceTeachers(state) {
        return state.attendanceTeachers;
    }
}