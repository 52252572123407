import {
    getSchedule,
    generateSchedule,
    getScheduleDetailById,
    takeAttendanceStudent,
    takeAttendanceTeacher,
    getClassesToMainView,
    getMyClassesByMySubscription
} from '../../../services/ScheduleService';
export default {
    async loadSchedule(context, payload = false) {

        if (!payload.forceRefresh && !context.getters.shouldUpdate) return;

        const response = await getSchedule(context.rootGetters.getToken, context.rootGetters['school/getSchoolId'])
        context.commit('setSchedule', response.data.schedules);
        context.commit('setFetchTimestamp');
    },
    async generateSchedule(context) {
        await generateSchedule(context.rootGetters.getToken, context.rootGetters['school/getSchoolId'])
        await context.dispatch('loadSchedule', {forceRefresh: true});
    },
    async getScheduleDetailById(context, idPayload) {
        const response = await getScheduleDetailById(idPayload.id, context.rootGetters.getToken, context.rootGetters['school/getSchoolId'])
        context.commit('setScheduleDetail', response.data.schedule)
    },
    async removeSelectedSchedule({commit}) {
        commit('setScheduleDetail', null);
    },
    async takeAttendance(context, payloadSchedule) {
        await takeAttendanceStudent(context.rootGetters['school/getSchoolId'], payloadSchedule.scheduleId, payloadSchedule.subscriptionId, context.rootGetters.getToken);
        const arrFilterStudents = context.getters.getDetail.students.filter(student => student._id !== payloadSchedule.studentId);
        const student = context.getters.getDetail.students.filter(student => student._id == payloadSchedule.studentId);

        context.commit('resetStudents', arrFilterStudents);
        context.commit('resetAttendacedStudent', {
            _id: student[0]._id,
            name: student[0].student[0].name,
            email:student[0].student[0].email,
            photo: student[0].student[0].photo
        });
    },
    async takeAttendanceTeacher(context, payloadSchedule) {
        await takeAttendanceTeacher(context.rootGetters['school/getSchoolId'], payloadSchedule.scheduleId, payloadSchedule.teacherId, context.rootGetters.getToken);
        const teacher = context.getters.getDetail.teachers.filter(teacherObj => teacherObj._id == payloadSchedule.teacherId);
        context.commit('setTeacherAttend', teacher[0]);
    },
    async loadSchedulesMainView(context) {
        const response = await getClassesToMainView();
        context.commit('setSchedulesMainView', response.data);
    },
    async loadMyClassesByMySubscription(context, limit) {
        const response = await getMyClassesByMySubscription(context.rootGetters.getCurrentUserId, context.rootGetters.getToken, limit);
        context.commit('setMySchedulesBySubscription', response.data);
    }
}