import { saveSubscription, loadSubscriptions } from '../../../services/SubscriptionService.js';
export default {
    async createSubscription(context, subscriptionPayload) {
        const newSubscription = {
            userSelected: subscriptionPayload.userId,
            package: subscriptionPayload.packageClassId,
            paymentMethod: subscriptionPayload.paymentMethod,
            timeExpire: subscriptionPayload.timeExpire,
            dateExpired: subscriptionPayload.dateExpired
        }
        await saveSubscription(newSubscription, context.rootGetters.getToken, context.rootGetters['school/getSchoolId']);
        //context.commit('saveNewSubscription', {...newSubscription, _id: response.data.subscription._id});
        context.dispatch("students/loadStudents", {forceRefresh: true}, {root: true});
        context.dispatch("getSubscriptions", {forceRefresh: true});
    },
    async getSubscriptions(context, payload = false) {

        if (!payload.forceRefresh && !context.getters.shouldUpdate) return;

        const subscriptions = await loadSubscriptions(context.rootGetters.getToken, context.rootGetters['school/getSchoolId']);
        context.commit('setSubscriptions', subscriptions.data.subscriptions);
        context.commit('setFetchTimestamp');
    }
}