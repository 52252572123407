export default {
    saveNewTypeDance(state, typeDancePayload) {
        state.typesDance.push(typeDancePayload);
    },
    setTypesDance(state, typesDance) {
        state.typesDance = typesDance;
    },
    setFetchTimestamp(state) {
        state.lastFetch = new Date().getTime();
    }
}