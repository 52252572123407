export default {
    setSchedule(state, payloadSchedule) {
        state.listSchedule = payloadSchedule
    },
    setScheduleDetail(state, payloadScheduleDetail) {
        state.scheduleDetail = payloadScheduleDetail
    },
    resetStudents(state, payloadStudent) {
        state.scheduleDetail.students = payloadStudent
    },
    resetAttendacedStudent(state, payloadStudent) {
        state.scheduleDetail.studentsAttended.push(payloadStudent); 
    },
    setTeacherAttend(state, payloadTeacher) {
        state.scheduleDetail.teachersAttended.push(payloadTeacher);
    },
    setFetchTimestamp(state) {
        state.lastFetch = new Date().getTime();
    },
    setSchedulesMainView(state, payloadSchedule) {
        state.schedulesMainView = payloadSchedule;
    },
    setMySchedulesBySubscription(state, payloadSchedules) {
        state.mySchedulesBySubscription = payloadSchedules;
    }
}