import axios from "axios";
const URL = process.env.VUE_APP_API_URL; 

export async function generateSchedule(payloadToken, schoolId) {
    const response = await axios.post(`${URL}/school/${schoolId}/schedule`, {},{
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return response.data;
}

export async function getSchedule(payloadToken, schoolId) {
    const schedule = await axios.get(`${URL}/school/${schoolId}/schedule`, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        },
        params: {
            sort: 'date',
        }
    });
    return schedule.data;
}

export async function getScheduleDetailById(scheduleId, payloadToken, schoolId) {
    const schedule = await axios.get(`${URL}/school/${schoolId}/schedule/${scheduleId}`, {
        headers: {
            Authorization: `Bearer ${payloadToken}`
        }
    });
    return schedule.data;
}

export async function takeAttendanceStudent(schoolId, scheduleId, subscriptionId, token) {
    const response = await axios.post(`${URL}/school/${schoolId}/schedule/${scheduleId}/attendance-student`, {
        subscription: subscriptionId
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function takeAttendanceTeacher(schoolId, scheduleId, teacherId, token) {
    const response = await axios.post(`${URL}/school/${schoolId}/schedule/${scheduleId}/attendance-teacher`, {
        teacher: teacherId
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function getClassesToMainView() {
    const classes = await axios.get(`${URL}/schedule`, {});
    return classes.data;
}

export async function getMyClassesByMySubscription(idUser, token, limit=100) {
    const teachers = await axios.get(`${URL}/users/${idUser}/classesInSubscription`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            limit: limit
        }
    });
    return teachers.data;
}