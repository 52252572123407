import axios from "axios";
const URL = process.env.VUE_APP_API_URL; 

export async function getUsers(token) {
    const response = await axios.get(`${URL}/users`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}

export async function teacherGetMyAttendanceByMonth(token) {
    const response = await axios.get(`${URL}/users/teacher-attendance`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
}