import { createStore } from 'vuex';

import studentsModule from './modules/students/index.js';
import schoolModule from './modules/school/index.js';
import subscriptionsModule from './modules/subscriptions/index.js';
import typesDanceModule from './modules/type-dance/index.js';
import packageModule from './modules/package/index.js';
import authModule from './modules/auth/index.js';
import userModule from './modules/user/index.js';
import scheduleModule from './modules/schedule/index.js'

const store = createStore({
    modules: {
        students: studentsModule,
        school: schoolModule,
        subscriptions: subscriptionsModule,
        typesDance: typesDanceModule,
        packages: packageModule,
        auth: authModule,
        user: userModule,
        schedule: scheduleModule
    },
    state() {
        return {
            currencySymbole: '€',
            paymentMethods: [
                {name: 'cash', value: 'cash'},
                {name: 'Revolut', value: 'revolut'},
                {name: 'Wise', value: 'wise'},
            ],
            roles: ['user', 'admin', 'owner', 'student', 'teacher', 'assistant']
        };
    },
    getters: {
        getCurrencySymbole(state) {
            return state.currencySymbole;
        },
        getPaymentM1ethods(state) {
            return state.paymentMethods;
        }
    },
});

export default store;