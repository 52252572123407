export default {
    saveNewUser(state, studentPayload) {
        state.currentStudentCreated = studentPayload;
    },
    setStudents(state, payloadStudents) {
        state.students = payloadStudents
    },
    setFetchTimestamp(state) {
        state.lastFetch = new Date().getTime();
    }
}