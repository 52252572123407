export default {
    listSchedule(state) {
        return state.listSchedule;
    },
    getDetail(state) {
        return state.scheduleDetail;
    },
    shouldUpdate(state) {
        const lastFetch = state.lastFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        return (currentTimeStamp - lastFetch) / 1000 > 120;
    },
    getSchedulesMainView(state) {
        return state.schedulesMainView;
    },
    getMySchedulesBySubscription(state) {
        return state.mySchedulesBySubscription;
    }
}