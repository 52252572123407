export default {
    saveNewPackage(state, packagePayload) {
        state.packages.push(packagePayload);
    },
    setListPackages(state, packagePayload) {
        state.packages = packagePayload;
    },
    setFetchTimestamp(state) {
        state.lastFetch = new Date().getTime();
    }
}