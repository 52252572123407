import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';

//Google Login
import vue3GoogleLogin from 'vue3-google-login'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
//icons
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { aliases, md } from 'vuetify/iconsets/md'

const vuetify = createVuetify({
  icons: {
    defaultSet: 'md',
    aliases,
     sets: {
       md,
     },
  },
  components,
  directives,
});

//router
import router from "./router.js";
//Store
import store from "./store/index.js";

// ----------------- FIREBASE INIT -------------------------------------------------
import firebase from "firebase/compat/app";
//VUE_APP_FIREBASE_DB_URL maybe we need this variable
var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_APY_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// ----------------- FIREBASE END --------------------------------------------------

//Service Worker for PWA
import './registerServiceWorker';

// Ui Components
//TODO: We have to change this for login page - another comment to deploy 
const BaseCard = defineAsyncComponent(() => import('./components/ui/BaseCard.vue'));
const BaseButton = defineAsyncComponent(() => import('./components/ui/BaseButton.vue'));
const BaseBadge = defineAsyncComponent(() => import('./components/ui/BaseBadge.vue'));
const ButtonFloat = defineAsyncComponent(() => import('./components/ui/ButtonFloat.vue'));
const BaseSpinner = defineAsyncComponent(() => import('./components/ui/BaseSpinner.vue'));
const BaseDialog = defineAsyncComponent(() => import('./components/ui/BaseDialog.vue'));

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('button-float', ButtonFloat);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);

app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
});

app.mount('#app')
