<template>
  <router-view></router-view>
</template>

<script>
export default {
  async beforeCreate() {
    try {
      await this.$store.dispatch("checkAuthLocalStorage");
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}
</style>
