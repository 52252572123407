export default {
    typesDance(state) {
        return state.typesDance
    },
    hasTypesDance(state) {
        return state.typesDance && state.typesDance.length > 0
    },
    shouldUpdate(state) {
        const lastFetch = state.lastFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        return (currentTimeStamp - lastFetch) / 1000 > 120;
    }
}