export default {
    user(state) {
        return state.user;
    },
    getUserList(state) {
        return state.listUsers;
    },
    teacherGetMyAttendanceByMonth(state) {
        return state.teacherMyAttendanceByMonth;
    }
}