export default {
    students(state) {
        return state.students
    },
    hasStudents(state) {
        return state.students && state.students.length > 0
    },
    currentStudentCreated(state) {
        return state.currentStudentCreated
    },
    shouldUpdate(state) {
        const lastFetch = state.lastFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        return (currentTimeStamp - lastFetch) / 1000 > 120;
    }
}